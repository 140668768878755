@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-thin-webfont.woff2') format('woff2'),
    url('./geomanist-thin-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-extralight-webfont.woff2') format('woff2'),
    url('./geomanist-extralight-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-light-webfont.woff2') format('woff2'),
    url('./geomanist-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-regular-webfont.woff2') format('woff2'),
    url('./geomanist-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-book-webfont.woff2') format('woff2'),
    url('./geomanist-book-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-medium-webfont.woff2') format('woff2'),
    url('./geomanist-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-bold-webfont.woff2') format('woff2'),
    url('./geomanist-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-black-webfont.woff2') format('woff2'),
    url('./geomanist-black-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist';
  src: url('./geomanist-ultra-webfont.woff2') format('woff2'),
    url('./geomanist-ultra-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
